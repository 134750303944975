import { AttributeValue, CategoryPath, Customer } from "@msdyn365-commerce/retail-proxy";
import { ICoreContext } from '@msdyn365-commerce/core';
import nbNO from './locale/nb-NO.json';
import enUS from './locale/en-US.json';
import { SimpleProductExt } from "../types";
import { ProductSearchResultExt } from "../../modules/vinhuset-search-result-container/components";

export function getAttribute(attributes: AttributeValue[] | undefined, attributeName: string): AttributeValue {
    if (!attributes) {
        throw new Error(`No attributes found.`);
    }

    const attribute = attributes?.find(x => x.Name === attributeName);

    if (!attribute) {
        throw new Error(`Attribute ${attributeName} not found.`);
    }

    return attribute;
}

export function getAttributeNumber(attributes: AttributeValue[] | undefined, attributeName: string): number {
    if (!attributes) {
        throw new Error(`No attributes found.`);
    }

    const attribute = attributes?.find(x => x.Name === attributeName);
    let result: number | undefined;

    if (!attribute) {
        throw new Error(`Attribute ${attributeName} not found.`);
    }

    switch (attribute.DataTypeValue) {
        case 1: // Currency
            result = attribute.CurrencyValue;
            break;
        case 3: // Decimal
            result = attribute.FloatValue;
            break;
        case 4: // Integer
            result = attribute.IntegerValue;
            break;
        case 0: // None
        case 2: // DateTime
        case 5: // Text
        case 6: // TrueFalse
            throw new Error(`Attribute ${attributeName} is not a number.`);
    }

    return result || 0;
}

export function getAttributeNumberOrDefault(attributes: AttributeValue[] | undefined, attributeName: string, defaultValue: number): number {
    if (!attributes) {
        return defaultValue;
    }

    const attribute = attributes?.find(x => x.Name === attributeName);
    let result: number | undefined;

    if (!attribute) {
        return defaultValue;
    }

    switch (attribute.DataTypeValue) {
        case 1: // Currency
            result = attribute.CurrencyValue;
            break;
        case 3: // Decimal
            result = attribute.FloatValue;
            break;
        case 4: // Integer
            result = attribute.IntegerValue;
            break;
        case 0: // None
        case 2: // DateTime
        case 5: // Text
        case 6: // TrueFalse
            result = defaultValue;
            break;
    }

    return result || 0;
}

export function getAttributeString(attributes: AttributeValue[] | undefined, attributeOrName: AttributeValue | string, suffix?: string): string {
    if (!attributes) {
        throw new Error(`No attributes found.`);
    }

    let attribute, result;

    if (typeof attributeOrName === 'string') {
        attribute = attributes?.find(x => x.Name === attributeOrName);
    } else {
        attribute = attributeOrName;
    }

    switch (attribute?.DataTypeValue) {
        case 0: // None
            result = `-`;
            break;
        case 1: // Currency
            result = attribute.CurrencyValue?.toString();
            break;
        case 2: // DateTime
            result = attribute.DateTimeOffsetValue ? new Date(attribute.DateTimeOffsetValue).toUTCString() : undefined;
            break;
        case 3: // Decimal
            result = attribute.FloatValue?.toString();
            break;
        case 4: // Integer
            result = attribute.IntegerValue?.toString();
            break;
        case 5: // Text
            result = attribute.TextValue?.toString();
            break;
        case 6: // TrueFalse
            result = attribute.BooleanValue?.toString();
            break;
    }

    if (!result) {
        throw new Error(`Attribute ${attributeOrName} not found`);
    }

    if (result !== '-' && suffix) {
        result += suffix;
    }

    return result;
}

export function getAttributeStringOrDefault<T>(attributes: AttributeValue[] | undefined, attributeName: string, defaultValue: T, suffix?: string): T | string {
    if (!attributes) {
        return defaultValue;
    }

    const attribute = attributes?.find(x => x.Name === attributeName);
    let result;

    if (!attribute) {
        return defaultValue;
    }

    switch (attribute?.DataTypeValue) {
        case 0: // None
            result = `-`;
            break;
        case 1: // Currency
            result = attribute.CurrencyValue?.toString();
            break;
        case 2: // DateTime
            result = attribute.DateTimeOffsetValue ? new Date(attribute.DateTimeOffsetValue).toUTCString() : undefined;
            break;
        case 3: // Decimal
            result = attribute.FloatValue?.toString();
            break;
        case 4: // Integer
            result = attribute.IntegerValue?.toString();
            break;
        case 5: // Text
            result = attribute.TextValue?.toString();
            break;
        case 6: // TrueFalse
            result = attribute.BooleanValue?.toString();
            break;
        default:
            result = '';
            break;
    }

    if (result && result !== '-' && suffix) {
        result += suffix;
    }

    return result || '';
}

export function getAttributesLocale(lang: string): typeof nbNO {
    switch (lang) {
        case 'nb-NO':
            return nbNO;
        case 'en-US':
            return {...nbNO, ...enUS};
        default:
            return nbNO;
    }
}

export function hasAttribute(attributes: AttributeValue[] | undefined, attributeName: string): boolean {
    if (!attributes) {
        return false;
    }

    return attributes?.some(x => x.Name === attributeName);
}

export function hasAccessToBuy(context: ICoreContext, customer: Customer | undefined, productAttributes: AttributeValue[] | undefined) {
    if (!productAttributes || productAttributes.length === 0) {
        // No product attributes available, default to true
        return true;
    }

    // @ts-expect-error
    const locale = getAttributesLocale(context.request.locale);
    const licensesToCheck: string[] = [];
    let hasAccess = true;

    for (const license of licensesToCheck) {
        const productAttribute = Number(productAttributes?.find(x => x.Name === license)?.TextValue);
        const customerAttribute = Number(customer?.Attributes?.find(x => x.Name === license)?.AttributeValue?.StringValue);

        // Product doesn't have the attribute. Default to has access
        if (isNaN(productAttribute) || productAttribute === 0) {
            continue;
        }

        // Product has the attribute and it's more than 0, but customer doesn't have it at all. No access
        if (isNaN(customerAttribute)) {
            hasAccess = false;
            break;
        }

        // Product has the attribute, but customer has a lower value. No access
        if (customerAttribute < productAttribute) {
            hasAccess = false;
            break;
        }
    }

    const attributeRecordId = Number(context.request.app.config?.customerSpecificRefinerRecordId);

    if (!isNaN(attributeRecordId) && attributeRecordId > 0) {
        const customerNumber = customer?.AccountNumber;
        const productAttribute = productAttributes?.find(x => x.RecordId === attributeRecordId)?.TextValue
            ?.split('|')
            .filter(Boolean);

        // Produt is customer specific
        if (productAttribute && productAttribute.length > 0 && !productAttribute.includes('0')) {
            if (!customerNumber || !productAttribute.includes(customerNumber)) {
                hasAccess = false;
            }
        }
    }

    return hasAccess;
}

export function applyAttributesToSimpleProduct(locale: string, product: SimpleProductExt, attributes: AttributeValue[] | undefined, categoryPaths: CategoryPath[] | undefined): void {
    if (attributes && attributes.length > 0) {
        const attrLocale = getAttributesLocale(locale);
        const alcoholPercentage = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.alcoholPercent, '-', '%');
        const volumeCl = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.volumeCl, undefined, ' cl')
            || getAttributeStringOrDefault(attributes, attrLocale.alternative.prefixed.volumeCl, undefined, ' cl');
        const producer = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.producer, undefined)
            || getAttributeStringOrDefault(attributes, attrLocale.alternative.prefixed.producer, undefined);
        const country = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.country, undefined)
            || getAttributeStringOrDefault(attributes, attrLocale.alternative.prefixed.country, undefined);
        const district = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.district, undefined)
            || getAttributeStringOrDefault(attributes, attrLocale.alternative.prefixed.district, undefined);
        const vintage = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.vintage, undefined);
        const soldOutUntilDate = attributes.find(x => x.Name === attrLocale.prefixedAttributes.soldOutUntilDate)?.DateTimeOffsetValue;

        product.AlcoholPercentage = alcoholPercentage;
        product.VolumeCl = volumeCl;
        product.Producer = producer;
        product.Country = country;
        product.District = district;
        product.CountryAndDistrict = [country, district].filter(Boolean).join(', ') || undefined;
        product.Vintage = vintage;
        product.SoldOutUntilDate = soldOutUntilDate ? new Date(soldOutUntilDate) : undefined;
    }

    product.CategoryPaths = categoryPaths;
    product.Category = product.CategoryPaths?.[0]?.Segments?.[1]?.CategoryName;
}

export function applyAttributesToProductSearchResult(locale: string, product: ProductSearchResultExt, attributes: AttributeValue[] | undefined, categoryPaths: CategoryPath[] | undefined): void {
    if (attributes && attributes.length > 0) {
        const attrLocale = getAttributesLocale(locale);
        const alcoholPercentage = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.alcoholPercent, '-', '%');
        const volumeCl = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.volumeCl, undefined, ' cl')
            || getAttributeStringOrDefault(attributes, attrLocale.alternative.prefixed.volumeCl, undefined, ' cl');
        const producer = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.producer, undefined)
            || getAttributeStringOrDefault(attributes, attrLocale.alternative.prefixed.producer, undefined);
        const country = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.country, undefined)
            || getAttributeStringOrDefault(attributes, attrLocale.alternative.prefixed.country, undefined);
        const district = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.district, undefined)
            || getAttributeStringOrDefault(attributes, attrLocale.alternative.prefixed.district, undefined);
        const vintage = getAttributeStringOrDefault(attributes, attrLocale.prefixedAttributes.vintage, undefined);
        const soldOutUntilDate = attributes.find(x => x.Name === attrLocale.prefixedAttributes.soldOutUntilDate)?.DateTimeOffsetValue;

        product.AlcoholPercentage = alcoholPercentage;
        product.VolumeCl = volumeCl;
        product.Producer = producer;
        product.Country = country;
        product.District = district;
        product.CountryAndDistrict = [country, district].filter(Boolean).join(', ') || undefined;
        product.Vintage = vintage;
        product.SoldOutUntilDate = soldOutUntilDate ? new Date(soldOutUntilDate) : undefined;
    }

    product.CategoryPaths = categoryPaths;
    product.Category = product.CategoryPaths?.[0]?.Segments?.[1]?.CategoryName;
}
